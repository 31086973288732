<template>
  <div class="container">
    <label class="label" :for="id">{{ label }}</label>
    <input
      class="input"
      type="text"
      :id="id"
      :value="modelValue"
      @input="updateValue"
    />
  </div>
</template>

<script>
import { generateUniqueId } from "../libs/tools";

export default {
  name: "text-input",
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      id: generateUniqueId(),
    };
  },
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  .flex-col();

  .label {
    font-size: 0.9rem;
    text-align: left;
    margin-bottom: 1px;
    font-weight: bold;
  }

  .input {
    padding: 10px 10px;
    border-radius: 5px;
    border: 1px solid @grey;
    font-size: 0.9rem;
  }
}
</style>
