<template>
  <div class="login">
    <div class="login-container">
      <div class="title">
        <span class="material-icons-outlined lock">lock</span>
        <span class="text">CONNEXION</span>
      </div>
      <div class="input-container">
        <TextInput
          class="input"
          :label="'Nom d\'utilisateur'"
          v-model="login"
        />
        <TextInput class="input" :label="'Mot de passe'" v-model="password" />
      </div>
      <div class="submit" @click="handleFormSubmit">
        <span>Valider</span>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "../components/TextInput.vue";

export default {
  name: "Connexion",
  components: { TextInput },
  data: () => {
    return {
      login: "admin",
      password: "admin",
    };
  },
  methods: {
    async handleFormSubmit() {
      const returnVal = await this.$store.dispatch("auth/connexion", {
        username: this.login,
        password: this.password,
      });

      if (returnVal !== false) this.$router.push({ name: "home" });
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  .flex-center();
  .flex-col();
  min-height: 100vh;

  .login-container {
    .flex-col();
    background-color: @white;
    padding: 40px 50px 50px 50px;
    border-radius: 20px;

    .title {
      .flex-center();
      margin-bottom: 10px;

      .lock {
        border: 1px dashed @grey;
        border-radius: 50%;
        padding: 8px;
      }

      .text {
        margin-left: 10px;
      }
    }

    .input-container {
      margin-top: 10px;

      .input {
        margin-top: 10px;
      }
    }

    .submit {
      background-color: @green;
      color: @white;
      font-weight: bold;
      border-radius: 5px;
      margin-top: 24px;
      padding: 10px 0;
      cursor: pointer;
      text-align: center;
    }
  }
}
</style>
